<template>
  <div class="resultsList">
    <div v-if="!loading && error == null && listings.length > 0" ref="listingsList" class="listingsList">
      <slot name="listingPlaceholder" />
      <ListingCard :placeholder="placeholder" @click-payment="e => $emit('click-payment', e)" @track="e => $emit('track', e)" v-for="(l, i) in listings" :key="l.id" :index="i" :details="l" :to="to" :target="target" @click="listingClicked" @compare="e => $emit('compare', e)" :compare-ids="compareIds" @on-toggle-favorite="e => $emit('on-toggle-favorite', e)">
        <template #financingTerms>
          <slot name="financingTerms" />
        </template>
      </ListingCard>
      <div v-if="paging?.total > listings.length && !loading" class="pages">
        <a :href="pageLink(paging.page - 1)" @click="jumpToPrev" aria-label="Previous Page" :title="t.prev_page" aria-roledescription="prev_page" rel="prev">
          <svg width="6" height="10" viewBox="0 0 6 10">
            <path d="M5 9L1 5L5 1" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </a>
        <a v-for="i in pagesList" :key="i" :href="pageLink(i)" :class="{ selected: paging.page === i, separator: i < 0 }" @click="jumpToPage(i)" :aria-label="'Page' + i" :title="t.page + ' ' + i">
          {{ i > 0 ? i : '...' }}
        </a>
        <a :href="pageLink(paging.page + 1)" @click="jumpToNext" aria-label="Next Page" :title="t.next_page" aria-roledescription="next_page" rel="next">
          <svg width="6" height="10" viewBox="0 0 6 10">
            <path d="M1 9L5 5L1 1" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </a>
      </div>
      <p v-if="brandData" class="SEO">{{ brandData.description }}</p>
    </div>
    <slot name="empty" v-if="isEmptySearch">
      <EmptySearch :is-button-action="true" :title="t['no_results_found']" :description="t['please_try_different_search']" :lg="lg" />
    </slot>
    <p v-if="brandData && listings.length === 0" class="SEO">
      {{ brandData.description }}
    </p>
    <p v-if="error != null" class="error">{{ error }}</p>
    <Loader v-if="loading" />
  </div>
</template>

<script>
import { formatFilterForApi, slugify } from '../../logic.js'
import SeezSdk from '../../sdk.js'
import { langMixin } from '../lang'
import ListingCard from '../ListingCard2.ce.vue'
import EmptySearch from '../EmptySearch.ce.vue'
import Loader from '../Loader.ce.vue'

export default {
  name: 'SeezResultsPanel',
  components: { ListingCard, Loader, EmptySearch },
  mixins: [langMixin('RESULT_PANEL_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  inheritAttrs: false,
  props: {
    modelValue: { type: Object, required: true },
    options: { type: Object, required: true },
    to: { type: String, default: null },
    target: { type: String, default: null },
    focusId: { type: String, default: null },
    placeholder: { type: String, default: null },
    pageLinkUrl: { type: String, default: null },
    compareIds: { type: String, default: '' }
  },
  emits: ['results', 'update:modelValue', 'click', 'click-payment', 'track', 'compare', 'on-toggle-favorite'],
  data() {
    return {
      listings: [],
      loading: false,
      error: null,
      paging: {}
    }
  },
  computed: {
    brandData() {
      if (this.listings[0]?.targetSite.name !== 'Marketplace') return null
      if (this.modelValue.brands == null) return null
      if (this.languageResources?.SEO_DATA == null) return null
      const brandOption = this.options.brands.find(o => o.id === this.modelValue.brands)
      if (brandOption == null) return null
      const brandSeo = this.languageResources.SEO_DATA[slugify(brandOption.name)]
      return brandSeo
    },
    listingsFilter() {
      const data = formatFilterForApi(this.modelValue)
      return data
    },
    isEmptySearch() {
      return this.listings.length === 0 && this.error == null && !this.loading
    },
    pagesList() {
      if (this.paging?.pages > 0 && this.paging?.page > 0) {
        const c = this.paging.page
        const t = this.paging?.pages
        const pagesWithDuplicates = [1, 2, 3, c - 2, c - 1, c, c + 1, c + 2, t - 2, t - 1, t]
        let pages = [...new Set(pagesWithDuplicates)].filter(i => i >= 1 && i <= t)
        pages.sort((a, b) => (parseInt(a) > parseInt(b) ? 1 : -1))
        let index = 0
        while (index < pages.length - 1) {
          if (pages[index] > 0 && pages[index] != pages[index + 1] - 1) {
            pages.splice(index + 1, 0, -index)
          }
          index++
        }

        return pages
        //return [...Array(this.paging.pages).keys()].map((i) => i + 1)
      }
      return []
    },
    title() {
      if (this.modelValue?.brands && this.options?.brands) return this.options?.brands?.find(b => b.id === this.modelValue.brands.toString())?.name
      return 'Search results'
    },
    description() {
      return null
    }
  },
  watch: {
    listingsFilter() {
      this.loadListings()
    }
  },
  mounted() {
    this.loadListings()
  },
  methods: {
    async loadListings() {
      this.abortController?.abort()
      this.abortController = new AbortController()

      const listingsQuery = 'query filteredListings($filter: ListingFiltersInput, $page: Int){listings(filter:$filter,page:$page,perPage:24){nodes ' + ListingCard.requiredFields + ' pageInfo {total page perPage pages}}}'

      const page = this.modelValue.page ?? 1
      const payload = { filter: JSON.parse(JSON.stringify(this.listingsFilter)), page: page }
      this.loading = true
      this.error = null
      try {
        const result = await this.queryApi(listingsQuery, this.cleanupFilters(payload))
        this.listings = result.listings.nodes
        this.paging = result.listings.pageInfo
        this.$emit('results', result.listings)
        this.loading = false
        if (this.focusId) {
          this.$nextTick(() => {
            const index = this.listings.findIndex(l => l.id === this.focusId)
            if (index > 0) this.$refs.listingsList.children[index].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
          })
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error(error)
          this.error = this.t.error_listing //TODO: fix reactiveness
          this.loading = false
        }
      }
    },
    cleanupFilters(payload) {
      if (!payload?.filter) {
        return payload
      }

      const newPayload = { ...payload }

      if (!newPayload.filter?.models?.length) {
        delete newPayload.filter?.models
      }
      if (!newPayload.filter?.brands?.length) {
        delete newPayload.filter?.brands
      }
      if (!newPayload.filter?.families?.length) {
        delete newPayload.filter?.families
      }

      return newPayload
    },
    listingClicked(e, details) {
      this.$emit('click', e, details)
    },
    sortDictionary(unordered) {
      return Object.keys(unordered)
        .sort()
        .reduce((obj, key) => {
          obj[key] = unordered[key]
          return obj
        }, {})
    },
    pageLink(i) {
      if (this.pageLinkUrl == null || i <= 0 || i > this.paging.pages || i === this.paging.page) return null
      return this.pageLinkUrl.replace('{index}', i)
    },
    jumpToPage(i) {
      if (i <= 0 || i > this.paging.pages || i === this.paging.page) return

      const oldValue = this.modelValue.page ?? 1
      if (i === oldValue) return

      const newFilter = this.sortDictionary({ ...this.modelValue, page: i })

      if (i <= 1) delete newFilter.page
      this.$emit('update:modelValue', newFilter)
    },
    jumpToNext() {
      const activePage = this.paging.page
      const totalPossiblePages = this.paging.total / this.paging.perPage
      const lastPage = Math.ceil(totalPossiblePages)

      if (activePage === lastPage) return

      const newFilter = this.sortDictionary({ ...this.modelValue, page: activePage + 1 })
      this.$emit('update:modelValue', newFilter)
    },
    jumpToPrev() {
      if (this.paging.page === 1) return

      const newFilter = this.sortDictionary({ ...this.modelValue, page: this.paging.page - 1 })
      this.$emit('update:modelValue', newFilter)
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

.resultsList {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas: 'results' 'pages' 'SEO';
  overflow: auto;
  margin-block-end: 4.5rem;
  background-color: var(--background);
  @include scrollBars;

  @media screen and (min-width: 40rem) {
    margin-block-end: 0;
  }

  .SEO {
    display: flex;
    flex-direction: column;
    color: #757575;
    font-size: 1rem;
    margin-block-start: 0.6rem;
    margin-block-end: 0.6rem;
  }

  > .listingsList {
    grid-area: results;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
    justify-content: flex-start;
    gap: 1em;
    padding: 1em;
    place-self: start stretch;
    overflow: visible;

    @include scrollBars;

    @media screen and (max-width: 28rem) {
      grid-template-columns: repeat(auto-fill, minmax(12em, 1fr));
    }

    > .pages {
      grid-column: 1 / -1;
      place-self: end center;
      display: flex;
      flex-direction: row;

      gap: 0.35em;

      > button,
      > a {
        text-decoration: none;
        line-height: 2em;
        text-align: center;
        display: inline-block;
        border: none;
        background-color: transparent;
        border-radius: 2em;
        padding: 0.25em;
        cursor: pointer;
        color: #333333;
        width: 2em;
        height: 2em;
        font-family: var(--base-font);

        &:hover,
        &:focus {
          background-color: #fafafa;
        }

        &.selected {
          background-color: #fafafa;
          color: var(--highlight);
          cursor: default;
          font-weight: 500;
        }

        &.separator {
          cursor: default;
          background-color: transparent;
          width: auto;
          padding: 0.5em 0;
        }
      }
    }

    > .SEO {
      grid-column: 1 / -1;
      place-self: center;

      gap: 0.35em;
    }
  }

  > p {
    grid-area: results;
    font-size: 2em;

    &.error {
      color: red;
    }
  }

  @media screen and (max-width: 42rem) {
    padding: 0;
  }

  .loader {
    grid-area: results;
    align-self: center;
    justify-self: center;
  }
}
</style>
