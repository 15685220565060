<template>
  <ul class="locationCards">
    <Loader v-if="loading" />
    <li v-else v-for="location in locations" :key="location.id" class="locationItem locationCard">
      <h3 class="locationHeading">{{ location.name }}</h3>
      <p>{{ location.plainAddress }}</p>
      <p v-if="location.phone">
        <svg width="11" height="11" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.2539 8.29688L13.3164 9.60938C13.8086 9.80078 14.082 10.3203 13.9727 10.8398L13.3164 13.9023C13.207 14.3945 12.7422 14.7773 12.25 14.7773C12.0586 14.7773 11.8945 14.75 11.7305 14.75C11.457 14.75 11.1836 14.7227 10.9375 14.6953C4.78516 14.0391 0 8.84375 0 2.5C0 2.00781 0.355469 1.54297 0.847656 1.43359L3.91016 0.777344C4.42969 0.667969 4.94922 0.941406 5.14062 1.43359L6.45312 4.49609C6.64453 4.93359 6.53516 5.45312 6.15234 5.75391L5.03125 6.68359C5.76953 7.94141 6.80859 8.98047 8.06641 9.71875L8.99609 8.59766C9.29688 8.21484 9.81641 8.10547 10.2539 8.29688ZM12.0586 13.4375L12.6328 10.7305L9.89844 9.55469L9.10547 10.5391C8.69531 11.0312 7.98438 11.168 7.41016 10.8398C5.96094 9.99219 4.75781 8.78906 3.91016 7.33984C3.58203 6.76562 3.71875 6.05469 4.21094 5.64453L5.19531 4.85156L4.01953 2.11719L1.3125 2.69141C1.39453 8.59766 6.15234 13.3555 12.0586 13.4375Z" fill="#0068FF" />
        </svg>
        <a class="locationLink" :href="`tel:${location.phone}`" @click="trackClick('phone', location)">{{ location.phone ?? '-' }}</a>
      </p>
      <p v-if="location.email">
        <svg width="10" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.75 1.8125C1.50391 1.8125 1.3125 2.03125 1.3125 2.25V2.87891L6.01562 6.73438C6.58984 7.19922 7.38281 7.19922 7.95703 6.73438L12.6875 2.87891V2.25C12.6875 2.03125 12.4688 1.8125 12.25 1.8125H1.75ZM1.3125 4.57422V9.25C1.3125 9.49609 1.50391 9.6875 1.75 9.6875H12.25C12.4688 9.6875 12.6875 9.49609 12.6875 9.25V4.57422L8.80469 7.74609C7.73828 8.62109 6.23438 8.62109 5.19531 7.74609L1.3125 4.57422ZM0 2.25C0 1.29297 0.765625 0.5 1.75 0.5H12.25C13.207 0.5 14 1.29297 14 2.25V9.25C14 10.2344 13.207 11 12.25 11H1.75C0.765625 11 0 10.2344 0 9.25V2.25Z" fill="#0068FF" />
        </svg>
        <a class="locationLink" :href="`mailto:${location.email}`" @click="trackClick('email', location)">{{ location.email ?? 'N/A' }}</a>
      </p>
      <p v-if="location.directions">
        <svg width="10" height="11" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.375 5C8.375 3.14844 6.85156 1.625 5 1.625C3.125 1.625 1.625 3.14844 1.625 5C1.625 5.30469 1.71875 5.75 1.97656 6.35938C2.21094 6.92188 2.5625 7.57812 2.96094 8.23438C3.64062 9.3125 4.41406 10.3438 5 11.0938C5.5625 10.3438 6.33594 9.3125 7.01562 8.23438C7.41406 7.57812 7.76562 6.92188 8 6.35938C8.25781 5.75 8.375 5.30469 8.375 5ZM9.5 5C9.5 7.0625 6.75781 10.6953 5.53906 12.2188C5.25781 12.5703 4.71875 12.5703 4.4375 12.2188C3.24219 10.6953 0.5 7.0625 0.5 5C0.5 2.51562 2.51562 0.5 5 0.5C7.48438 0.5 9.5 2.51562 9.5 5ZM5.75 5C5.75 4.60156 5.39844 4.25 5 4.25C4.57812 4.25 4.25 4.60156 4.25 5C4.25 5.42188 4.57812 5.75 5 5.75C5.39844 5.75 5.75 5.42188 5.75 5ZM3.125 5C3.125 4.34375 3.47656 3.73438 4.0625 3.38281C4.625 3.05469 5.35156 3.05469 5.9375 3.38281C6.5 3.73438 6.875 4.34375 6.875 5C6.875 5.67969 6.5 6.28906 5.9375 6.64062C5.35156 6.96875 4.625 6.96875 4.0625 6.64062C3.47656 6.28906 3.125 5.67969 3.125 5Z" fill="#0068FF"/>
        </svg>
        <a class="locationLink" :href="location.directions" target="_blank" @click="trackClick('directions', location)">{{ t.getDirections }}</a>
      </p>
    </li>
    <p v-if="error" class="error">{{ t.noAvailableData }}</p>
  </ul>
</template>
<script>
import SeezSdk from '@/sdk.js'
import Loader from '../Loader.ce.vue'
import { analyticsMixin } from '@/analytics.js'
import { langMixin } from '@/components/lang.js'

export default {
  name: 'LocationCards',
  components: { Loader },
  mixins: [SeezSdk.vueQueryMixin, analyticsMixin, langMixin('LOCATION_CARD')],
  props: {
    showroomIds: { type: String, default: '' },
    dealershipId: { type: String, default: '' },
    locationIds: { type: String, default: null }
  },
  data() {
    return {
      locations: [],
      loading: false,
      error: false,
      directionsCustomAttrKey: 'directions'
    }
  },
  watch: {
    t() {}
  },
  mounted() {
    this.getLocations()
  },
  methods: {
    convertToArrayOfInt(ids) {
      if (!ids) return null

      return ids
        .replace(/['“”]/g, '')
        .split(',')
        .map(id => +id)
    },
    async getLocations() {
      this.loading = true
      const selectedIds = this.locationIds ? this.locationIds : this.showroomIds
      const ids = this.convertToArrayOfInt(selectedIds)

      const query = 'query showrooms($ids: [ID!]) { showrooms(ids: $ids) { id name email phone plainAddress }}'
      const queryNew = 'query dealerships($ids: [ID!]) { dealerships(ids: $ids) { id name email phone plainAddress customAttributes { key value } }}'

      try {
        const locationResponse = this.locationIds ? await this.queryApi(queryNew, { ids }).then(x => x.dealerships) : await this.queryApi(query, { ids, dealerhipIds: [this.dealershipId] }).then(x => x.showrooms)

        if (!locationResponse || locationResponse.length === 0) {
          this.loading = false
          this.error = true
          return
        }

        const filteredLocations = locationResponse.filter(item => item !== null).map(item => {
          if (item.customAttributes) {
            for (const attr of item.customAttributes) {
              if (attr.key === this.directionsCustomAttrKey) {
                item.directions = attr.value
                break
              }
            }
          }
          return item
        })

        this.locations = filteredLocations
      } catch (e) {
        console.log(e)
        this.error = true
      } finally {
        this.loading = false
      }
    },
    trackClick(resource, location) {
      this.track('locations_click_' + resource, { locationId: location.id, [resource]: location[resource] })
    }
  }
}
</script>

<style lang="scss">
.locationCards {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 0;
  padding: 0;
  font-size: 16px;

  .loader {
    height: 3em;
    width: 3em;
  }

  .locationCard {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    width: 235px;
    padding: 1.1875em 0.8125em;
    margin: 0.625em 0;
    font-size: 0.625em;
    display: flex;
    flex-direction: column;
    gap: 0.625em;

    .locationHeading {
      margin: 0;
      font-size: 13px;
    }

    .locationLink {
      text-decoration: none;
      color: var(--base-color);
      text-decoration: underline;

      &:hover {
        opacity: 0.7;
      }
    }

    p {
      display: flex;
      align-items: center;
      gap: 0.625em;
      margin: 0;
      padding: 0;
      font-size: 10px;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .locationItem {
    list-style: none;
  }

  .error {
    color: red;
  }
}
</style>
