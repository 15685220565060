<template>
  <div class="financingDetails" v-if="order">
    <h4>{{ t.financingDetailClassic ?? 't.financingDetailClassic' }}</h4>
    <dl>
      <dt>{{ t.monthlyPayment ?? 't.monthlyPayment' }}</dt>
      <dd>{{ formatNumber(financing.calculation.monthlyPayment, 2, owner.currency) }} {{ t.months }}</dd>
      <dt>{{ t.loanDuration ?? 't.loanDuration' }}</dt>
      <dd>{{ financing.calculation.paymentTerms }} {{ t.months }}</dd>
      <dt>{{ t.totalLoanAmount ?? 't.totalLoanAmount' }}</dt>
      <dd>{{ formatNumber(financing.calculation.totalLoanCost, 2, owner.currency) }}</dd>
      <dt>{{ t.downPayment ?? 't.downPayment' }}</dt>
      <dd>{{ formatNumber(financing.calculation.downPayment, 2, owner.currency) }}</dd>
      <template v-for="(rate, key) in financing.calculation.rates" :key="key">
        <dt v-if="rate?.key !== 'interestRate'">{{ t[rate?.key] ?? rate?.key }}</dt>
        <dd v-if="rate?.key !== 'interestRate'">{{ Number(rate?.value) }}%</dd>
      </template>
      <dt>{{ t.totalPayable ?? 't.totalPayable' }}</dt>
      <dd>{{ formatNumber(financing.calculation.totalPayable, 2, owner.currency) }}</dd>
    </dl>

    <div class="apply" v-if="financing.redirectUrl">
      <Loader v-if="loading" />
      <button class="goToApplication" v-if="!loading" @click="applyForFinancing">
        {{ t.goToApplication ?? 't.goToApplication' }}
        <ShareIcon />
      </button>
      <p>
        {{ t.financingProvidedBy }}
        <img class="logo" :src="financing?.config?.provider?.logo" :alt="financing?.config?.provider?.name" loading="lazy" />
      </p>
    </div>
    <div class="apply" v-else-if="financing.state === 'draft'">
      <Loader v-if="loading" />
      <button class="goToApplication" v-if="!loading" @click="submitPayment">{{ t.submitApplication ?? 't.submitApplication' }}</button>
      <p>
        {{ t.financingProvidedBy }}
        <img class="logo" :src="financing?.config?.provider?.logo" :alt="financing?.config?.provider?.name" loading="lazy" />
      </p>
    </div>
    <div class="apply" v-else>
      <p>
        {{ t.financingProvidedBy }}
        <img class="logo" :src="financing?.config?.provider?.logo" :alt="financing?.config?.provider?.name" loading="lazy" />
      </p>
    </div>
  </div>
</template>

<script>
import { langMixin } from '../lang'
import Loader from '../Loader.ce.vue'
import SeezSdk from '../../sdk.js'
import { analyticsMixin } from '../../analytics.js'
import ShareIcon from '@/assets/external-link.svg'

export default {
  name: 'FinancingDetails',
  components: { ShareIcon, Loader },
  mixins: [langMixin('FINANCING_PROVIDER_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin, analyticsMixin],
  props: {
    order: { type: Object, required: true }
  },
  emits: ['onFinancingUpdated'],
  data() {
    return {
      loading: false,
      financing: this.order?.payment?.financing,
      owner: this.order?.listing?.owner
    }
  },
  computed: {
    financingQuery() {
      return `
        financing {
          state
          redirectUrl
          selected
          calculation {
            apr
            aopBeforeTax
            financedAmount
            loanAmount
            downPayment
            downPaymentPct
            totalPayable
            totalLoanCost
            paymentTerms
            monthlyPayment
            nominalInterestRate
            interestType
            interestRate
            rates { key value }
          }
          config {
            interestTypes
            terms
            downPayment { min max default }
            provider { name logo }
          }
        }
      `
    },
    shouldUpdateFinancingState() {
      return ['draft', 'pending'].includes(this.financing.state)
    }
  },
  methods: {
    toMoney(value, currency) {
      return this.formatPrice({ value }, currency)
    },
    async applyForFinancing() {
      if (this.financing.redirectUrl) {
        console.log('this.financing', this.financing)
        if (this.shouldUpdateFinancingState) {
          await this.updatePayment('submitted')
        }
        window.open(this.financing.redirectUrl, '_blank')
      }
    },
    async updatePayment(state) {
      this.loading = true

      try {
        const { updatePayment } = await this.queryApi(
          `mutation {
            updatePayment(orderId: ${this.order.id}, input: {financing: {state: ${state}}}) {
              ${this.financingQuery}
            }
          }`
        )

        this.financing = updatePayment.financing
        this.$emit('onFinancingUpdated', this.financing)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async submitPayment() {
      this.loading = true

      try {
        const { submitPayment } = await this.queryApi(
          `mutation {
            submitPayment(orderId: ${this.order.id}) {
              ${this.financingQuery}
            }
          }`
        )

        this.financing = submitPayment.financing
        this.$emit('onFinancingUpdated', this.financing)
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss">
.financingDetails {
  .line {
    border: 1px solid rgba(198, 201, 202, 1);
  }
  .des {
    font-size: 12px;
  }
  > h4 {
    margin: 0rem 0rem 1rem 0rem;
  }
  > dl {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1em;
    > dd {
      text-align: right;
      font-weight: 700;
    }

    @media screen and (min-width: 46rem) {
      grid-template-columns: 1fr auto 1fr auto;
    }
  }

  > .apply {
    // background-color: var(--accented-background);
    padding: 2rem;
    text-align: center;

    > button {
      // color: var(--background);
      // background-color: var(--highlight);
      border: none;
      // border-radius: 3em;
      font-size: 1em;
      padding: 0.5em 1em;
    }

    > p {
      width: max-content;
      margin: 1em auto 0;
      display: flex;
      align-items: center;
      gap: 1em;

      > img {
        height: 1em;
      }
    }
  }
}
</style>
